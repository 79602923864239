/**
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createAction } from 'typesafe-actions';

import { CollectionFilter } from './models';

export const addCollectionFilter = createAction(
  '@firestore/addCollectionFilter'
)<
  {
    path: string;
  } & CollectionFilter
>();
export const removeCollectionFilter = createAction(
  '@firestore/removeCollectionFilter'
)<{
  path: string;
}>();
